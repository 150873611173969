<template>
    <div>
        <div v-if="canDisplayBoosts()">
            <div v-for="action in actionsList" :key="action.key">
                <div v-if="action.type == 'boost' && action.positions_opened_today < action.boosts" class="action_box">
                    Boost: 
                    <router-link
                        class="security_symbol"
                        :to="{ name: 'asset', params: { symbol: action.asset_symbol } }"
                    >{{ action.asset_symbol }}</router-link>
                    ({{ action.positions_opened_today }}/{{ action.boosts }})
                    <div class="buttons">
                        <div v-if="processingActions.includes(action.key)">
                            <b-spinner
                                small 
                                type="grow" 
                                class="button_spinner"
                                variant="primary"
                            ></b-spinner>
                        </div>
                        <div v-else>
                            <b-button 
                                size="sm" 
                                class="button_default" 
                                variant="primary" 
                                @click.prevent="submitBoost(action.asset_symbol)"
                            >
                                <strong>{{ buttonLabel(action.key) }}</strong>
                            </b-button>
                        </div>
                    </div>
                </div>

                <div v-if="action.type == 'buy' && action.positions_opened_today < action.boosts" class="action_box">
                    <strong>
                        Buy 
                        <router-link
                            class="security_symbol"
                            :to="{ name: 'asset', params: { symbol: action.asset_symbol } }"
                        >{{ action.asset_symbol }}</router-link>
                        ({{ action.positions_opened_today }}/{{ action.boosts }})
                    </strong>

                    <div class="buttons">
                        <b-button 
                            size="sm" 
                            class="button_default" 
                            variant="info" 
                            @click.prevent="suggestBuy(action.asset_symbol)"
                        >
                            <strong>Buy {{ action.asset_symbol }}</strong>
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Actions',
    data() {
        return {
            actionsList: [],
            processingActions: [],
        }
    },
    created() {
        this.retrieveActions();
    },
    methods: {
        canDisplayBoosts() {
            let d = new Date();
            
            // Convert to US Eastern Time
            let estTime = d.toLocaleString("en-US", { timeZone: "America/New_York" });
            let estHours = new Date(estTime).getHours();

            // Return true if the time is between 1 PM and 5 PM
            return estHours >= 13 && estHours < 17;
        },
        retrieveActions() {
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/actions-v2')
                .then((response) => {
                    this.actionsList = []; // reset the local list, new list is here
                    let actions = response.data;
                    var i;                  
                    for (i = 0; i < actions.length; i++) {
                        if (actions[i].type == 'boost') {
                            actions[i].key = "boost:".concat(actions[i].asset_symbol)
                        }
                        this.actionsList.push(actions[i])
                    }
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
        submitBoost(symbol) {
            this._boostAsset(symbol).then(() => {
                this.$store.dispatch('refreshData');
            });
        },
        buttonLabel(actionKey) {
            var i;                  
            for (i = 0; i < this.actionsList.length; i++) {
                if (this.actionsList[i].key == actionKey) {
                    if (this.actionsList[i].type == 'boost') {
                        return "Boost ".concat(this.actionsList[i].asset_symbol);
                    }
                }
            }
        },
        processingKey(actionKey) {
            if (actionKey in this.processingActions) return true;
            else return false;
        },
        suggestBuy(symbol) {
            this.$emit('suggest-buy-symbol', symbol)
        },
        _boostAsset(symbol) {
            const actionKey = "boost:".concat(symbol);
            this.processingActions.push(actionKey);

            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.$store.state.baseURL[this.$store.state.env] + '/api/boost/process',
                        {
                            symbol: symbol,
                        }
                    )
                    .then((response) => {
                        //console.log(response.data);
                        this.retrieveActions()
                        this.processingActions.splice(this.processingActions.indexOf(actionKey), 1);
                        this._removeAction(actionKey);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        this.processingActions.splice(this.processingActions.indexOf(actionKey), 1);
                        reject(error);
                    });
            });
        },
        _removeAction(actionKey) {
            var i;                  
            for (i = 0; i < this.actionsList.length; i++) {
                if (this.actionsList[i].key == actionKey) {
                    this.actionsList.splice(this.actionsList[i], 1);
                }
            }
        }
    },
}
</script>

<style>

.action_box { 
    color: #000; 
    padding: 12px;
    border-top: 1px solid #ccc; 
    background-color: #fff3b0; 
    position: relative;
}
.buttons {
    position: absolute;
    right: 0px;
    margin-top: -28px;
}
.button_default {
    width: 100px;
    margin-right: 12px;
}
.button_spinner {
    margin-right: 54px;
}

</style>