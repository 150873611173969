<template>
    <div class="default-style">
        <div class="mb-5">
            <h2>
                <span class="mr-3">Buy {{ form.symbol }}</span>
                <span v-if="asset" class="text-secondary">
                    <span class="mr-3">${{ formatPrice(asset.price) }}</span>
                </span>
            </h2>
            <p v-if="lowestPosition && form.symbol == asset?.symbol">
                <span v-if="oneLiner" class="text-info d-block my-2">
                    {{ oneLiner }}
                    <br />
                </span>
                

                <span class="text-secondary">
                    lowest 
                    <span class="text-white">${{ lowestPosition.size }} @ {{ lowestPosition.open_price }}</span> on 
                    <span class="text-white">{{ formatTime(new Date(lowestPosition.open_date)) }}</span>
                </span>
                
                <br />
                <span class="text-warning" v-if="positionDistance?.value < 0">distance: {{ positionDistance.formatted }}</span>
            </p>
        </div>

        <b-form acton="#" @submit.prevent="onSubmit" autocomplete="off" class="mt-3">
            <b-row class="mb-3">
                <b-col cols=4>
                    <b-form-select 
                        v-model="form.symbol" 
                        :options="assets.assetSymbolList"
                        name="symbol"
                        id="symbol"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>[Asset]</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols=4>
                    <b-form-input v-if="asset"
                        type="number" 
                        step="any"
                        name="price" 
                        id="price" 
                        :placeholder="asset.price.toString()"
                        v-model="form.price"
                    ></b-form-input>
                    <b-form-input v-else
                        type="text" 
                        name="price" 
                        id="price" 
                        placeholder="$price"
                        v-model="form.price"
                    ></b-form-input>
                </b-col>
                <b-col cols=4>
                    <b-form-select 
                        v-model="form.positions" 
                        :options="positionsOptions"
                        name="no_positions"
                        id="no_positions"
                    >
                    </b-form-select>
                </b-col>
            </b-row>



            <b-row class="mb-3" v-if="form.positions > 10">
                <b-col cols=4>
                    <b-form-select 
                        v-model="form.bucket_public_id" 
                        :options="bucketFormOptions"
                        name="bucket"
                        id="bucket"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>[Bucket]</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols=4>
                    <b-form-select 
                        v-model="form.custom_buy" 
                        :options="customBuyOptions"
                        name="custom_buy"
                        id="custom_buy"
                    >
                    </b-form-select>
                </b-col>
                <b-col cols=4>
                    
                    <b-form-input 
                        :state="limitLabelLength"
                        type="text" 
                        name="label" 
                        id="label" 
                        placeholder="Bulk add label"
                        v-model="form.label"
                    ></b-form-input>
                    
                </b-col>
            </b-row>

            <b-overlay
                :show="addPositionBusy"
                rounded
                opacity="0.1"
                spinner-small
                spinner-variant="light"
                class="d-inline-block"
                >
                <b-button v-if="canSubmit" type="submit" variant="primary">Add position</b-button>
                <b-button v-else disabled type="submit" variant="primary">Add position</b-button>
            </b-overlay>
        </b-form>
    </div>
</template>

<script>
import { mapState } from "vuex";
//import axios from 'axios';

import bucketMixin from '@/mixins/bucketMixin';
import formatter from '@/mixins/formatter';

export default {
    name: "SuggestBuy",
    props: ['symbol'],
    mixins: [bucketMixin, formatter],
    components: {
    },
    data() {
        return {
            lowestPosition: null,
            asset: null,

            form: {
                symbol: null,
                positions: 1,
                size: 50,
                price: null,
                label: null,
                bucket_public_id: null,
                custom_buy: null,
            },
            //bucketOptions: ['Time', 'Revolut', 'eToro', 'IBKR', 'SAXO', 'Binance', 'Kraken', 'Other'],
            positionsOptions: (() => {let opt = []; for (let i = 1; i <= 80; i++) { opt.push({ value: i, text: '$'+(i*50)+' ('+i+' pos)' }) } return opt;})(),
            customBuyOptions: [
                { value: null, text: 'Default'},
                { value: 'multiplier10p', text: 'Long exit @ +20%' },
                { value: 'bonus20p', text: 'Bonus exit @ +30%' }, 
                //{ value: 'order_cancelled', text: 'Order cancelled' }
            ],
            selected: null,
            positionSize: 50,

            addPositionBusy: false,
        }
    },
    created() {
        if (this.symbol) {
            this.asset = this.$store.getters.getAssetBySymbol(this.symbol)
            this.lowestPosition = JSON.parse(this.asset.cached_stats.lowest_open_position);
            this.form.symbol = this.asset.symbol;
        }
    },
    methods: {
        getDistance(open_price, current_price) {
            let distance = Math.round((current_price / open_price - 1) * 1000) / 10;

            return {
                value: distance,
                formatted: (distance > 0 ? '+'+distance: ''+distance) +'%'
            }
        },
        formatPrice(price) {
            var formatter = new Intl.NumberFormat('en-US');

            if (price > 0.1) return formatter.format(price);
            return price
        },
        onSubmit() {     
            this.addPositionBusy = true;

            if (this.canSubmit) {
                let payload = {
                    symbol: this.form.symbol,
                    size: this.form.size,
                    price: this.form.price,
                    label: this.form.label?.substring(0, 64),
                    positions: this.form.positions,
                }

                if (this.form.positions > 10 && this.form.bucket_public_id) {
                    let bucket = this.getBucketByPublicId(this.form.bucket_public_id)
                    
                    payload['bucket_public_id'] = bucket.public_id;
                    payload['bucketName'] = bucket.name;
                    payload['custom_buy'] = this.form.custom_buy;
                }

                this.$store
                    .dispatch("addNewPositions", payload)
                    .then(() => {
                        this.$store.dispatch('refreshData').then(() => {
                            //this.$emit('new-positions', lowestPosition)
                            this.$FModal.hide();
                        }).catch((error) => {
                            this.errorMessage = error.response.data
                            this.addPositionBusy = false;
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data
                        this.addPositionBusy = false;
                    });
            }
            else {
                this.addPositionBusy = false;
            }
        },
    },
    computed: {
        ...mapState({
            assets: state => state.assets,
        }),
        canSubmit() {
            if (this.form.symbol && this.form.size && this.form.price && this.form.positions && 
                ((this.form.positions > 10 && this.form.label) || (this.form.positions <= 10))) return true;
            return false;
        },
        limitLabelLength() {
            return (this.form.label?.length > 1 && this.form.label?.length < 64) ? true : false;
        },
        positionDistance() {
            if ( ! (this.lowestPosition && this.asset.price)) return undefined;
            return this.getDistance(this.lowestPosition.open_price, this.asset.price);
        },
        oneLiner() {
            return this.asset.meta?.oneLiner;
        }
    },
    watch: {
        'form.symbol': function (newValue) {
            this.asset = this.$store.getters.getAssetBySymbol(newValue);
            this.lowestPosition = JSON.parse(this.asset.cached_stats.lowest_open_position);
        }
    },
}
</script>
<style scoped>
    .default-style { 
        color: #fff; 
        padding: 30px 20px 50px 20px; 
    }
</style>