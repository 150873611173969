<template>
    <rounded-container variant="dark" v-if="groupList?.length">
        <template v-slot:title>Groups (locked)</template>

        <b-container class="p-0">
            <div class="p-3">
                <b-row class="text-center">
                    <b-col>
                        <h3>{{ formatHoldings(groupsSummary['value_long_buy']) }}</h3>
                        <strong class="text-secondary">{{ groupsSummary['no_long_buy'] }} long buys</strong>
                    </b-col>
                    <b-col>
                        <h3>{{ formatHoldings(groupsSummary['value_converted']) }}</h3>
                        <strong class="text-secondary">{{ groupsSummary['no_converted'] }} converted</strong>
                    </b-col>
                </b-row>

                <div class="text-center pt-3">
                    <b-link @click.prevent="expanded = !expanded" class="text-white show-more-less" href="#">
                        <span v-if="!expanded">Show more ({{ groupList.length - staleGroups.length }})</span>
                        <span v-else>Show less</span>
                    </b-link>
                </div>
            </div>

            <div v-for="(group, rowIndex) in closedGroups" :key="group.public_id" :class="rowClass(rowIndex)">
                <div :class="highlightGroup(group)" class="p-3">
                    <b-row class="text-secondary">
                        <b-col>
                            <span v-if="group.stage_change_time">{{ formatTimeFromISO(group.stage_change_time + 'Z') }} </span>
                            <span v-else>N/A </span>

                            <strong>
                                <span v-if="!currentAsset">{{ group.asset_symbol  }}</span>
                                <span class="format-price"> ${{ formatHoldings(group.value) }}</span> 
                                @ <span class="format-price">{{ group.starting_open }}</span>
                            </strong> <span>{{ group.name }}</span>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div v-for="(group, rowIndex) in staleGroups" :key="group.public_id" :class="rowClass(rowIndex)">
                <div :class="highlightGroup(group)" class="p-3">
                    <b-row no-gutters>
                        <b-col cols=2>
                            <b-badge v-if="group.stage == 'long_buy'" variant="info">long</b-badge>
                            <b-badge v-if="group.stage == 'monthly_draw'" variant="info" class="mb-1">monthly</b-badge>

                            <br /><span class="ml-1">x{{ group.multiplier }}</span>
                        </b-col>
                        <b-col>
                            <span><strong v-if="!currentAsset">{{ group.asset_symbol }} </strong></span>
                            <span class="format-price">${{ formatHoldings(group.value) }}</span> 
                            @<span class="format-price">{{ formatPrice(group.open_price) }}</span> → <span class="format-price">{{ formatPrice(group.open_price * group.multiplier) }}</span>

                            <div class="text-secondary">{{ group.name }}</div>

                            <b-badge variant="info" class="mr-2">{{ group.bucket }}</b-badge>
                            <b-badge class="format" v-if="getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).value > (group.multiplier * 100 - 100)" variant="success">{{ getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).formatted }}</b-badge>
                            <b-badge v-else-if="getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).value > 0">{{ getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).formatted }}</b-badge>
                            <price-distance v-else :openPrice="group.open_price" :currentPrice="getAssetMapper[group.asset_symbol].price" />
                        </b-col>
                        <b-col cols=3 class="text-right">
                            <span class="text-secondary">{{ formatTimeFromISO(group.added_time + 'Z') }}</span><br />
                            <div v-if="groupDaysSinceChange(group) > 330" class="mt-2">
                                <b-button v-if="currentAsset" @click.prevent="manageGroup(group)" size="sm" variant="warning">manage</b-button>
                                <b-button v-else size="sm" variant="warning" :to="{ name: 'asset', params: { symbol: group.asset_symbol } }">Go to {{ group.asset_symbol }}</b-button>
                            </div>
                            <div v-else class="mt-2">
                                <b-button v-if="currentAsset" @click.prevent="manageGroup(group)" size="sm" variant="dark">manage</b-button>
                                <b-button v-else size="sm" variant="dark" :to="{ name: 'asset', params: { symbol: group.asset_symbol } }">Go to {{ group.asset_symbol }}</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div v-for="(group, rowIndex) in longBuyGroups" :key="group.public_id" :class="rowClass(rowIndex)">
                <div class="p-3">
                    <b-row no-gutters>
                        <b-col cols=2>
                            <b-badge v-if="group.stage == 'long_buy'" variant="info">long</b-badge>
                            <b-badge v-if="group.stage == 'monthly_draw'" variant="info" class="mb-1">monthly</b-badge>

                            <br /><span class="ml-1">x{{ group.multiplier }}</span>
                        </b-col>
                        <b-col>
                            <span><strong v-if="!currentAsset">{{ group.asset_symbol }} </strong></span>
                            <span class="format-price">${{ formatHoldings(group.value) }}</span> 
                            @<span class="format-price">{{ formatPrice(group.open_price) }}</span> → <span class="format-price">{{ formatPrice(group.open_price * group.multiplier) }}</span>

                            <div class="text-secondary">{{ group.name }}</div>

                            <b-badge variant="info" class="mr-2">{{ group.bucket }}</b-badge>
                            <b-badge class="format" v-if="getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).value > (group.multiplier * 100 - 100)" variant="success">{{ getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).formatted }}</b-badge>
                            <b-badge v-else-if="getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).value > 0">{{ getDistance(group.open_price, getAssetMapper[group.asset_symbol].price).formatted }}</b-badge>
                            <price-distance v-else :openPrice="group.open_price" :currentPrice="getAssetMapper[group.asset_symbol].price" />
                        </b-col>
                        <b-col cols=3 class="text-right">
                            <span class="text-secondary">{{ formatTimeFromISO(group.added_time + 'Z') }}</span><br />
                            <div v-if="groupDaysSinceChange(group) > 330" class="mt-2">
                                <b-button v-if="currentAsset" @click.prevent="manageGroup(group)" size="sm" variant="warning">manage</b-button>
                                <b-button v-else size="sm" variant="warning" :to="{ name: 'asset', params: { symbol: group.asset_symbol } }">Go to {{ group.asset_symbol }}</b-button>
                            </div>
                            <div v-else class="mt-2">
                                <b-button v-if="currentAsset" @click.prevent="manageGroup(group)" size="sm" variant="dark">manage</b-button>
                                <b-button v-else size="sm" variant="dark" :to="{ name: 'asset', params: { symbol: group.asset_symbol } }">Go to {{ group.asset_symbol }}</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div v-for="(group, rowIndex) in convertedGroups" :key="group.public_id" :class="rowClass(rowIndex)">
                <div :class="highlightGroup(group)" class="p-3">
                    <b-row no-gutters>
                        <b-col cols=2>
                            <b-badge v-if="group.stage == 'converted'" variant="warning" class="mb-1">{{ group.positions_open }}/{{ group.positions_count }}</b-badge>
                            <b-badge v-else variant="danger" class="mb-1">{{ group.stage }}</b-badge>
                        </b-col>
                        <b-col>
                            <strong v-if="!currentAsset">{{ group.asset_symbol  }}</strong>
                            <span class="format-price"> ${{ group.value }}</span> @ <span class="format-price">{{ group.starting_open }} </span>
                            
                            <div class="text-secondary">{{ group.name }}</div>
                            
                            <b-badge variant="info" class="mr-2">{{ group.bucket }}</b-badge>
                            <price-distance v-if="getAssetMapper[group.asset_symbol]" :openPrice="group.starting_open" :currentPrice="getAssetMapper[group.asset_symbol].price" />
                        </b-col>
                        <b-col cols=3 class="text-right">
                            <span class=""><span v-if="group.stage == 'converted'">Converted<br /></span>{{ formatTimeFromISO(group.stage_change_time + 'Z') }}</span><br />
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-container>

        <!-- Disable manual group creation -->
        <!-- <b-container v-if="currentAsset" class="text-center p-3">
            <div v-if="refreshRequired">
                <b-button @click.prevent="refreshAsset" pill variant="warning">refresh {{ currentAsset.symbol }}</b-button>
            </div>
            <div v-else>
                <b-button @click.prevent="addAssetGroup" pill variant="dark">New {{ currentAsset.symbol}} group</b-button>
            </div>
        </b-container> -->
        
    </rounded-container>
</template>

<script>

import formatter from '../mixins/formatter'

//import AssetSymbol from './style/AssetSymbol.vue';
import RoundedContainer from './style/RoundedContainer.vue';
// import { mapState } from 'vuex';
import PriceDistance from './style/PriceDistance.vue';
import { mapGetters } from 'vuex'

export default {
    name: 'GroupList',
    mixins: [formatter],
    components: {
        RoundedContainer,
        //AssetSymbol,
        PriceDistance
    },
    props: ['groupList', 'asset', 'requireRefresh'],
    data() {
        return {
            requireAssetRefresh: false,
            currentAsset: null,
            expanded: false,
        }
    },
    created() {
        this.currentAsset = this.asset
    },
    methods: {
        rowClass(rowIndex) {
            if (rowIndex % 2) return 'oddRow';

            return 'evenRow'
        },
        groupDaysSinceChange(group) {
            let refDate = new Date(group.stage_change_time + 'Z')
            let curDate = new Date()
            let difDays = (curDate.getTime() - refDate.getTime()) / (1000 * 3600 * 24)

            return difDays
        },
        highlightGroup(group) {
            if (this.groupDaysSinceChange(group) > 330) {
                return 'highlight-row'
            }
            
            return null
        },
        manageGroup(group) {
            this.$emit('manage-group', {
                    group: group,
                    asset: this.currentAsset
                });
            this.$emit('require-refresh')
        },
        addAssetGroup() {
            this.$emit('asset-add-group', {
                defaultGroupType: 'long_buy',
                asset: this.currentAsset
            });
            this.$emit('require-refresh')
        },
        refreshAsset() {
            this.$emit('request-refresh')
        },
    },
    computed: {
        ...mapGetters([
            'getAssetMapper'
        ]),
        staleGroups() {
            return this.groupList.filter(group => group.stage == 'long_buy' && this.groupDaysSinceChange(group) > 330)
        },
        closedGroups() {
            return this.groupList.filter(group => this.expanded && group.stage === 'closed')
        },
        longBuyGroups() {
            return this.groupList.filter(group => this.expanded && group.stage === 'long_buy' && this.groupDaysSinceChange(group) <= 330)
        },
        convertedGroups() {
            return this.groupList.filter(group => this.expanded && group.stage === 'converted')
        },
        groupsSummary() {
            return this.groupList.reduce((map, group) => { 
                if ( ! map[`value_${group.stage}`]) {
                    map[`value_${group.stage}`] = 0
                    map[`no_${group.stage}`] = 0
                }
                map[`value_${group.stage}`] += group.value;
                map[`no_${group.stage}`] += 1;
                return map;
            }, {});
        },
        valueLong() {
            let value = 0
            
            for (let i=0; i<this.groupList.length; i++) {
                if (this.groupList[i].stage == 'long_buy') value += this.groupList[i].value
            }

            return value
        },
        sharesLong() {
            let shares = 0
            
            for (let i=0; i<this.groupList.length; i++) {
                if (this.groupList[i].stage == 'long_buy') shares += (this.groupList[i].value / (this.groupList[i].open_price * this.groupList[i].multiplier))
            }

            return shares
        },
        valueDraws() {
            let value = 0
            
            for (let i=0; i<this.groupList.length; i++) {
                if (this.groupList[i].stage == 'monthly_draw') value += this.groupList[i].value
            }

            return value
        },
        sharesDraws() {
            let shares = 0
            
            for (let i=0; i<this.groupList.length; i++) {
                if (this.groupList[i].stage == 'monthly_draw') shares += (this.groupList[i].value / (this.groupList[i].open_price * this.groupList[i].multiplier))
            }

            return shares
        },
        sumPendingGroupShares() {
            let sumShares = 0

            for (let i=0; i < this.groupList.length; i++) {
                if (this.groupList[i].stage != 'converted' && this.groupList[i].stage != 'closed') {
                    sumShares += (this.groupList[i].value / (this.groupList[i].open_price * this.groupList[i].multiplier))
                }
            }

            return sumShares
        },
        refreshRequired() {
            return this.requireRefresh
        }
    },
}
</script>

<style>
.highlight-row { background-color: hsla(0,0%,100%,.15); }
</style>