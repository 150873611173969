<template>
    <div class="modal-style">
        <h2>
            <span class="mr-2">Add balance</span>
            <span class="text-secondary">
                <span class="mr-2">{{ currentAsset.symbol }}</span>
            </span>
        </h2>

        <b-container class="pt-2 px-0 mb-3" v-if="currentAsset.balances.length">
            <b-row 
                    v-for="(balance, rowIndex) in currentAsset.balances" 
                    :key="balance.public_id"
                    :class="rowClass(rowIndex)"
                    class="py-2 modal-row"
                >
                <b-col>
                    <a href="#" @click.prevent="changeBucket(balance.bucket)">{{ balance.bucket.name }}</a>&nbsp;<span v-if="balance.bucket.type == 'trading'" class="text-info">&#8226;</span>
                </b-col>
                <b-col class="format-price">{{ formatHoldings(balance.shares) }}</b-col>
                <b-col class="format-price">${{ formatHoldings(balance.shares * currentAsset.price) }}</b-col>
                <b-col>{{ formatTimeFromISO(balance.last_check + 'Z') }}</b-col>
            </b-row>
        </b-container>

        <b-form acton="#" @submit.prevent="onSubmit" autocomplete="off" class="mt-4">
            <b-row class="">
                <b-col>
                    <b-form-select 
                        v-model="form.bucket" 
                        :options="bucketOptions"
                        name="bucket"
                        id="target-bucket"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Bucket --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col>
                    <b-form-input
                        type="number" 
                        step="any"
                        name="balance" 
                        id="balance" 
                        :placeholder="null"
                        v-model="form.balance"
                    ></b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-overlay
                        :show="addBalanceBusy"
                        rounded
                        opacity="0.1"
                        spinner-small
                        spinner-variant="light"
                        class="d-inline-block"
                        >
                        <b-button v-if="canSubmit" type="submit" variant="primary">Add</b-button>
                        <b-button v-else disabled type="submit" variant="primary">Add</b-button>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
//import { mapState } from "vuex";
import axios from 'axios';

import formatter from '../../mixins/formatter'

export default {
    name: 'AddBalance',
    props: ['assetContext'],
    mixins: [formatter],
    components: {

    },
    data() {
        return {
            currentAsset: null,

            form: {
                bucket: null,
                balance: null,
            },
            bucketOptions: [],
            addBalanceBusy: false,
        }
    },
    created() {
        this.currentAsset = this.$store.getters.getAssetBySymbol(this.assetContext.symbol);
        this.getBucketOptions()
    },
    methods: {
        changeBucket(bucket) {
            this.form.bucket = bucket.public_id;
        },
        getBucketOptions() {
            axios.defaults.headers.common['X-Access-Token'] = this.$store.state.user.accessToken;
            axios
                .get(this.$store.state.baseURL[this.$store.state.env] + '/api/buckets')
                .then((response) => {
                    let buckets = response.data
                    buckets.forEach(bucket => this.bucketOptions.push({
                        text: bucket.name,
                        value: bucket.public_id,
                    }))
                })
                .catch((error) => {
                    // daca primesc 401 (ar trebui sa fie in error.request), pot sa invalidez token-ul si sa fac redirect
                    if (error.request.status == 401) {
                        localStorage.removeItem('access_token');
                        this.$store.context.commit('destroyAccessToken');
                    }
                });
        },
        async onSubmit() {
            this.addBalanceBusy = true;

            if (this.canSubmit) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            this.$store.state.baseURL[this.$store.state.env] + '/api/bucket/'+this.form.bucket+'/add-balance',
                            {
                                asset_symbol: this.currentAsset.symbol,
                                balance_value: parseFloat(this.form.balance)
                            }
                        )
                        .then((response) => resolve(response.data.asset))
                        .catch((error) => {
                            this.addBalanceBusy = false;
                            reject(error);
                        })
                }).then(asset => {
                    this.$store
                            .dispatch('updateAssets', [asset])
                            .then(() => this.$FModal.hide())
                })
            }
        
            this.addBalanceBusy = false;
        },
    },
    computed: {
        canSubmit() {
            if (this.form.bucket && this.form.balance) return true;
            return false;
        },
    }
}
</script>

<style>
.modal-row.oddRow {
    background-color: hsla(0,0%,100%,.1);
}
</style>