<template>
    <b-form acton="#" @submit.prevent="onSubmit" autocomplete="off" class="p-3">
        <b-row no-gutters>
            <b-col class="exit-summary formatPrice">
                <span class="text-gray-400">+8%</span> {{ formatPrice(JSON.parse(currentAsset.cached_stats.lowest_open_position).open_price * 1.08) }}<br />
                <span class="text-gray-400">+10%</span> {{ formatPrice(JSON.parse(currentAsset.cached_stats.lowest_open_position).open_price * 1.1) }}
            </b-col>
            <b-col cols="4">
                <b-form-input
                    type="number"
                    step="any" 
                    name="balance" 
                    id="balance" 
                    placeholder="price"
                    v-model="form.price"
                ></b-form-input>
            </b-col>
            <b-col cols="3" class="text-right">
                <b-overlay
                    :show="formLoadingState"
                    opacity="0.1"
                    spinner-small
                    spinner-variant="light"
                    class="d-inline-block"
                    >
                    <b-button type="submit" :variant="buttonVariant">lookup</b-button>
                </b-overlay>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import formatter from '@/mixins/formatter';
import assetMixin from '@/mixins/assetMixin';

import ProcessExit from '@/components/overlays/ProcessExit.vue';

export default {
    props: ['currentAsset'],
    name: 'AssetExitsAtTarget',
    mixins: [
        formatter, 
        assetMixin
    ],

    data() {
        return {
            checkedExits: false,
            form: {
                price: null,
            },
            formLoadingState: false,
            formError: false,
            buttonVariant: 'dark',
            modalStyle: { 
                backgroundColor: '#000',

                contentStyles: { 
                    borderRadius: '10px 10px 0px 0px',
                    borderTop: '3px solid #333'
                } 
            },
        }
    },
    created() {

    },
    methods: {
        onSubmit() {
            if (this.form.price && this.form.price > 0) {
                this.buttonVariant = 'dark';

                this.$FModal.show(
                    { 
                        component: ProcessExit, 
                        ...this.modalStyle
                    }, 
                    { 
                        currentAsset: this.currentAsset,
                        targetPrice: this.form.price
                    }
                )
            }
            else {
                this.buttonVariant = 'warning';
                console.log('No target price specified.')
            }
        }
    },
}
</script>

<style lang="scss" scoped>
$gray-400: #ced4da !default;

.exit-summary { 
    font-size: 0.85rem;
    height: calc(1.5em + 0.75rem + 2px);
    vertical-align: middle;
}

.text-gray-400 {
    color: $gray-400;
}

</style>